/* index.css */

/* Import Tailwind's base, component, and utility layers */
@tailwind base; /* Base styles including resets and default typography */
@tailwind components; /* Component-level styles (e.g., forms, buttons) */
@tailwind utilities; /* Utility classes for layout and design */

/* Custom styles applied to the base layer */
@layer base {
  html {
    /* Applies a dark slate background and gray text color globally */
    @apply bg-slate-900 text-gray-300;
  }

  .dark {
    /* Styles for dark mode, providing a slightly lighter background and text color */
    @apply bg-gray-800 text-gray-100;
  }
}


/*
 General scrollbar width setting 
.custom-scrollbar::-webkit-scrollbar {
  width: 8px; 
}*/

/* Scrollbar track styling */
/*.custom-scrollbar::-webkit-scrollbar-track {
  background: #1f2937; /* Tailwind's gray-800 for a dark track background } */



